import request from "./request"

//会员登录
export const login = (data) => {
    return request("post", "/api/member/codeLogin", data);
}

//通过流水号登录
export const loginBySerialNum = (serialNum) => {
    return request("get", "/api/member/loginBySerialNum", { serialNum: serialNum });
}

//绑定手机号
export const bindPhone = (data) => {
    return request("post", "/api/member/bindPhone", data);
}