import CryptoJS from "crypto-js";

/**
 * base64加密
 * @param {}} data 
 * @returns 
 */
export const base64encode = (data) => {
    return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(data));
}

/**
 * url安全的base64加密
 * @param {}} data 
 * @returns 
 */
export const base64encodeUrlSafe = (data) => {
    data = base64encode(data);
    return data.replaceAll("+", "-").replaceAll("/", "_").replaceAll("=", "");
}
/**
 * base64解密
 * @param {*} data 
 * @returns 
 */
export const base64decode = (data) => {
    return CryptoJS.enc.Base64.parse(data).toString(
        CryptoJS.enc.Utf8
    );
}

export const wxappid = "wx90e8169f8046101a";
export const wxRedirctUrl = "http://console.icuapi.com/op/wxLogin";